import React from "react"

import gabinet1 from "../images/gabinet-1-low.jpg"
import gabinet2 from "../images/gabinet-2-low.jpg"
import gabinet3 from "../images/gabinet-3-low.jpg"

export default function Office({ children }) {
    
  return (
    <section className="elementor-section elementor-top-section elementor-element elementor-element-72e760b elementor-section-stretched dsvy-col-stretched-none dsvy-bg-color-over-image elementor-section-boxed elementor-section-height-default elementor-section-height-default dsvy-col-stretched-no dsvy-bgimage-no dsvy-bgcolor-yes">
    <div className="elementor-container elementor-column-gap-default">
        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-238baf9 dsvy-bg-color-over-image dsvy-bgimage-no dsvy-bgcolor-yes" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-f813852 dsvy-align-center elementor-widget elementor-widget-dsvy_heading" data-widget_type="dsvy_heading.default">

                    <div class="elementor-element elementor-element-174c7ae elementor-widget elementor-widget-dsvy_service_element" data-widget_type="dsvy_service_element.default">
                        <div class="elementor-widget-container">

                            <div class="designervily-element designervily-element-service dsvy-element-service-style-3 designervily-element-viewtype-row-column designervily-gap-35px" data-margin="35px">
                                <div class="designervily-element-inner">

                                    <div class="dsvy-ele-header-area">
                                            <div class="dsvy-heading-subheading left-align "></div>

                                    </div>

                                    <div class="dsvy-element-posts-wrapper row multi-columns-row">

                                        <article class="dsvy-ele dsvy-ele-service dsvy-service-style-3 col-md-6 col-lg-4 surgery  ">
                                            <div class="designervily-post-item">
                                                <div class="dsvy-featured-wrapper"><img loading="lazy" width="700" height="605" src={gabinet1} class="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image" alt="Gabinety Raszyn"/></div>
                                            </div>
                                        </article>

                                        <article class="dsvy-ele dsvy-ele-service dsvy-service-style-3 col-md-6 col-lg-4 saving-lives  ">
                                            <div class="designervily-post-item">
                                                <div class="dsvy-featured-wrapper"><img loading="lazy" width="750" height="635" src={gabinet2} class="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image" alt="Gabinety Raszyn"/></div>
                                            </div>
                                        </article>

                                        <article class="dsvy-ele dsvy-ele-service dsvy-service-style-3 col-md-6 col-lg-4 great-care  ">
                                            <div class="designervily-post-item">
                                                <div class="dsvy-featured-wrapper"><img loading="lazy" width="750" height="635" src={gabinet3} class="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image" alt="Gabinety Raszyn"/></div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
    </section>
  )
}